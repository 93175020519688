import { store } from "./store"
import { loaded } from "../features/matrix/matrixSlice"

/**
 * Init fonts
 */
export const initFontsEventListeners = () => {
  if(!document?.fonts) {
    store.dispatch(loaded())
  }

  document.fonts.ready.then(() => {
    store.dispatch(loaded())
  }).catch(() => {
    store.dispatch(loaded())
  })
}
