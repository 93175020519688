import styles from './Debug.module.css'
import React from "react"
import { useAppSelector } from "../../app/hooks"
import {
  selectFps,
  selectMaxStreamCount,
} from "../matrix/matrixSlice"

export const Debug = () => {
  const maxStreamCount = useAppSelector(selectMaxStreamCount)
  const fps = useAppSelector(selectFps)

  return (
    <div className={styles.Debug}>
      <ul className={styles.list}>
        <li>FPS: { fps }</li>
      </ul>
    </div>
  )
}
