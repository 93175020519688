import React, { useLayoutEffect} from "react"
import '../App.css'
import { Info } from "../features/info/Info";
import { useAppDispatch  } from "../app/hooks"
import {
  NAME_TICK_INTERVAL
} from "../app/constants"
import { nameTick } from "../features/name/thunks"

type IntervalType = ReturnType<typeof setInterval>

function MainPage() {
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    let nameTickInterval: IntervalType;

    nameTickInterval = setInterval(() => {
      dispatch(nameTick())
    }, NAME_TICK_INTERVAL)

    return () => {
      clearInterval(nameTickInterval)
    }
  }, [dispatch])

  return <Info />;
}

export default MainPage;
