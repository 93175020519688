import React, { useLayoutEffect } from "react";
import { Wishlist } from "../features/wishlist/Wishlist";
import { useAppDispatch } from "../app/hooks";
import { startMatrix } from "../features/matrix/matrixSlice";

export const WishlistPage = () => {
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    setTimeout(() => {
      dispatch(startMatrix())
    }, 500)
  }, [])

  return (
    <Wishlist />
  )
}
