import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

const infoSlice = createSlice({
  name: 'info',
  initialState: {
    typingDone: false,
  },
  reducers: {
    setTypingDone: (state) => {
      state.typingDone = true
    }
  }
})

export const { setTypingDone } = infoSlice.actions

export const selectTypingDone = (state: RootState) => state.info.typingDone

export default infoSlice.reducer
