import { AppThunk } from "../../app/store";
import { startMatrix } from "../matrix/matrixSlice";
import { setTypingDone } from "./infoSlice";

/**
 * Typing fone
 */
export const startTyping = (): AppThunk => (dispatch) => {
  dispatch(setTypingDone())
  dispatch(startMatrix())
}
