import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import matrix from '../features/matrix/matrixSlice'
import name from '../features/name/nameSlice'
import info from '../features/info/infoSlice'
import { initFontsEventListeners } from "./fonts"

initFontsEventListeners()

export const store = configureStore({
  reducer: {
    matrix,
    name,
    info
  },
});


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
