import styles from "./List.module.css";
import React, {useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import Typewriter from "typewriter-effect";
import {INFO_TYPEWRITER_DELAY} from "../info/constants";
import {isHoverEnabled} from "../info/utils";

type ListProps = {
  list: string[],
  onTypingStart?: () => void,
  useTypewriter?: boolean,
}

export const List = ({list, onTypingStart, useTypewriter = false}: ListProps) => {
  const [canHover, setCanHover] = useState<boolean>(false)
  const [cursorFlickering, setCursorFlickering] = useState<boolean>(false)
  const typewriterOptions = useMemo(() => ({
    cursor: "&nbsp;",
    cursorClassName: styles.cursor,
    delay: INFO_TYPEWRITER_DELAY,
  }), [])


  useEffect(() => {
    if (isHoverEnabled()) {
      setCanHover(true)
    }
  }, [])

  useEffect(() => {
    if(!useTypewriter) {
      onTypingStart?.();
    }
  }, [useTypewriter])

  return (
    <div className={classNames({
        [styles.list]: true,
        [styles.hoverable]: canHover,
        [styles['cursor-flickering']]: cursorFlickering,
      }
    )}>
      {useTypewriter ? <Typewriter
        options={typewriterOptions}
        onInit={(typewriter) => {
          typewriter
            .callFunction(() => {
              onTypingStart?.();
            })

          for (let i = 0; i < list.length; ++i) {
            typewriter.typeString(`${list[i]}${i < list.length - 1 ? '<br />' : ''}`)
          }

          typewriter
            .callFunction(() => {
              setCursorFlickering(true)
            })
            .start()
        }}
      />
        :
        <ul className={classNames({
          [styles.list]: true,
        })}>
        {
          list.map((item, i) => {
            return (
              <li key={i} dangerouslySetInnerHTML={{
                __html: item
              }} />
            )
          })
        }
      </ul>}
    </div>
  )
}
