import React from "react";
import {
    createBrowserRouter,
} from "react-router-dom";
import "./index.css";
import Main from "./pages/MainPage";
import {WishlistPage} from "./pages/WishlistPage";

export const router = createBrowserRouter([
    {
        path: "*",
        element: <Main />,
    },
    {
        path: "/wishlist",
        element: <WishlistPage />,
    },
]);