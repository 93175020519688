import styles from "./Name.module.css"
import { FC } from "react"
import { useAppSelector } from "../../app/hooks"
import { selectName } from "./nameSlice"


export const Name: FC = () => {
  const name = useAppSelector(selectName)

  return (<h1>{
    name.split("").map((char, i) =>
      <span key={ i } className={ styles.item }>{ char }</span>
    )
  }</h1>)
}
