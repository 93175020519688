import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { Name } from "./types"
import { NAME } from "./constants"

const getInitialState = (): Name => ({
  name: '',
  encodedCharIndexes: new Array(NAME.length).fill(null).map((_,i)=> i)
})

const nameSlice = createSlice({
  name: "name",
  initialState: getInitialState(),
  reducers: {
    setName: (state, action) => {
      state.name = action.payload
    },
    decodeChar: (state, action) => {
      state.encodedCharIndexes = state.encodedCharIndexes.filter((index) => index !== action.payload)
    }
  }
})

export const { setName, decodeChar } = nameSlice.actions

export const selectName = (state: RootState) => state.name.name
export const selectEncodedCharsIndexes = (state: RootState) => state.name.encodedCharIndexes

export default nameSlice.reducer
