import styles from "./Info.module.css"
import {useAppDispatch, useAppSelector} from "../../app/hooks"
import { selectIsDescriptionStarted } from "../matrix/matrixSlice"
import { useEffect, useMemo, useState } from "react"
import classNames from "classnames"
import Typewriter from "typewriter-effect"
import { Name } from "../name/Name"
import { PROFESSION, SCENE_STEP_DELAY, INFO_TYPEWRITER_DELAY } from "./constants";
import {Screen} from "../screen/Screen";
import { List } from "../list/List";
import {startTyping} from "./thunks";

export const Info = () => {
  const descriptionStarted = useAppSelector(selectIsDescriptionStarted)
  const dispatch = useAppDispatch();

  /**
   * Typewriter stuff
   */
  const [ animationStarted, setAnimationStarted ] = useState(false)
  const [ typingSubheader, setTypingSubheader ] = useState<boolean>(false)
  const typewriterOptions = useMemo(() => ({
    cursor: "&nbsp;",
    cursorClassName: styles.cursor,
    delay: INFO_TYPEWRITER_DELAY,
  }), [])

  useEffect(() => {
    if (descriptionStarted) {
      setTimeout(() => {
        setAnimationStarted(true)
        setTypingSubheader(true)
      }, SCENE_STEP_DELAY)
    }
  }, [ descriptionStarted ])

  return (
    <Screen>
      <div className={classNames({
        [styles.Info]: true,
      })}>
        <Name/>
        { animationStarted && <>
          <h2>
            { typingSubheader ? <Typewriter
              options={ typewriterOptions }
              onInit={ (typewriter) => {
                typewriter
                  .typeString(PROFESSION)
                  .callFunction(() => {
                    setTypingSubheader(false)
                  })
                  .start()
              } }
            /> : PROFESSION }
          </h2>
          { typingSubheader ? null :
            <List
              useTypewriter={true}
              onTypingStart={() => {
                dispatch(startTyping())
              }}
              list={[
              `<a href="https://www.linkedin.com/in/ivan-moskwin/" target="_blank">LinkedIn</a>`,
              `<a href="https://t.me/imoskwin" target="_blank">Telegram</a>`,
              `<a href="https://github.com/ivan-moskvin/" target="_blank">GitHub</a>`,
              `<a href="https://leetcode.com/ivan-moskvin/" target="_blank">LeetCode</a>`,
              ]}/> }
        </> }
      </div>
    </Screen>
  )
}
