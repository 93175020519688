import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Matrix } from "./types"
import { RootState } from "../../app/store"
import { chance, getColsCount, getRowsCount, getWindowHeight, getWindowWidth, rand } from "./utils"
import { StreamShufflingPositionsSet, StreamType } from "../stream/type"
import {
  STREAM_MIN_LENGTH,
  SHORT_STREAM_SPAWN_CHANCE,
  SHORT_STREAM_START_ROW,
  SHORT_STREAM_MAX_LENGTH,
  SHORT_STREAM_MIN_LENGTH,
  NO_BRIGHT_GLYPHS_STREAM_CHANCE,
  STREAM_MAX_LENGTH_COEF,
  MIN_SHUFFLING_SPEED,
  MAX_SHUFFLING_SPEED,
  STREAM_SPAWN_CHUNK_SIZE, STREAM_SHUFFLING_POSITION_DENSITY
} from "../../app/constants"
import { noInfo } from "../../app/utils"

const getInitialState = (): Matrix => ({
  activeStreams: {},
  availableCols: new Array(getColsCount()).fill(null).map((_, i) => i),
  colsCount: getColsCount(),
  rowsCount: getRowsCount(),
  width: getWindowWidth(),
  height: getWindowHeight(),
  isMatrixStarted: false,
  isDescriptionStarted: false,
  loading: true,
  maxStreamCount: 1,
  fps: 0,
  performanceCeilReached: false,
})
const initialState = getInitialState()

const matrixSlice = createSlice({
  name: "matrix",
  initialState,
  reducers: {
    loaded: (state) => {
      state.loading = false
    },
    startMatrix: (state) => {
      if(!state.isMatrixStarted) {
        document.body.dispatchEvent(new Event('startMatrix'))
      }
      state.isMatrixStarted = true
    },
    startDescription: (state) => {
      state.isDescriptionStarted = true
    },
    pause: (state) => {
      state.isMatrixStarted = !state.isMatrixStarted
    },
    resize: (state, action) => {
      const { width, height } = action.payload

      state.width = width
      state.height = height
    },
    reset: (state) => {
      const { loading, isDescriptionStarted } = state

      return {
        ...getInitialState(),
        loading,
        isDescriptionStarted,
        isMatrixStarted: true,
      }
    },
    spawnStream: (state) => {
      const currentCol = rand(0, state.availableCols.length - 1)
      const rows = state.rowsCount
      const isShort = chance(SHORT_STREAM_SPAWN_CHANCE)
      const columnForStream = state.availableCols[currentCol]

      const getVisibleStartsFrom = (): number => {
        const maxVisibleStartsFrom = Math.max(rows - SHORT_STREAM_START_ROW, Math.floor(rows * STREAM_MAX_LENGTH_COEF))

        if(isShort) {
          return rand(0, maxVisibleStartsFrom)
        }

        return 0
      }

      const getMaxBodyLength = (): number => {
        const minLength = STREAM_MIN_LENGTH
        const maxLength = Math.floor(rows * STREAM_MAX_LENGTH_COEF)

        if(isShort) {
          return rand(SHORT_STREAM_MIN_LENGTH, SHORT_STREAM_MAX_LENGTH)
        }

        return rand(minLength, maxLength)
      }

      const getShufflingPositions = (): StreamShufflingPositionsSet => {
        const set: StreamShufflingPositionsSet = {}

        for(let i = 0; i <= rows; ++i) {
          if(chance(STREAM_SHUFFLING_POSITION_DENSITY)) {
            set[i] = true
          }
        }

        return set;
      }

      state.availableCols.splice(currentCol, 1)

      state.activeStreams[columnForStream] = {
        col: columnForStream,
        payload: [],
        isBuilded: false,
        currentBodyLength: 0,
        maxBodyLength: getMaxBodyLength(),
        tailLength: 0,
        maxLength: rows,
        visibleStartsFrom: getVisibleStartsFrom(),
        shufflingSpeed: rand(MIN_SHUFFLING_SPEED, MAX_SHUFFLING_SPEED),
        shufflingPositions: getShufflingPositions(),
        isShort,
        noBright: isShort || chance(NO_BRIGHT_GLYPHS_STREAM_CHANCE),
      }
    },
    updateStream: (state, action: PayloadAction<StreamType>) => {
      const { col } = action.payload

      state.activeStreams[col] = action.payload
    },
    disposeStream: (state, action: PayloadAction<number>) => {
      delete state.activeStreams[action.payload]
      state.availableCols.push(action.payload)
    },
    setFps: (state, action: PayloadAction<number>) => {
      state.fps = action.payload
    },
    increaseMaxStreamCount: (state) => {
      state.maxStreamCount += Math.min(STREAM_SPAWN_CHUNK_SIZE, state.colsCount - state.maxStreamCount)
    },
    decreaseMaxStreamCount: (state) => {
      if(state.maxStreamCount > 1) {
        state.maxStreamCount--
      }
    },
    performanceCeilReached: (state) => {
      state.performanceCeilReached = true
    }
  }
})

export const selectActiveStreamsCollection = (state: RootState) => Object.values(state.matrix.activeStreams)
export const selectActiveStreamsCount = createSelector([selectActiveStreamsCollection], streams => streams.length)
export const selectScreenColsAndRows = (state: RootState) => ({
  cols: state.matrix.colsCount,
  rows: state.matrix.rowsCount
})
export const selectDimensions = (state: RootState) => ({ width: state.matrix.width, height: state.matrix.height })
export const selectIsMatrixStarted = (state: RootState) => noInfo() || state.matrix.isMatrixStarted
export const selectIsDescriptionStarted = (state: RootState) => state.matrix.isDescriptionStarted
export const selectLoading = (state: RootState) => state.matrix.loading
export const selectFps = (state: RootState) => Math.ceil(state.matrix.fps)
export const selectMaxStreamCount = (state: RootState) => state.matrix.maxStreamCount
export const selectPerformanceCeilReached = (state: RootState) => state.matrix.performanceCeilReached

export const {
  startMatrix,
  startDescription,
  loaded,
  pause,
  reset,
  resize,
  spawnStream,
  disposeStream,
  updateStream,
  setFps,
  performanceCeilReached,
  increaseMaxStreamCount,
  decreaseMaxStreamCount
} = matrixSlice.actions

export default matrixSlice.reducer
