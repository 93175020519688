import { GLYPH_HEIGHT, GLYPH_WIDTH } from "../../app/constants"

/**
 * Gets cols
 */
export const getColsCount = (): number => {
  return Math.floor(window.innerWidth / GLYPH_WIDTH)
}

/**
 * Gets rows
 */
export const getRowsCount = (): number => {
  return Math.floor(window.innerHeight / GLYPH_HEIGHT)
}

/**
 * Generates random number
 * @param min
 * @param max
 */
export const rand = (min: number, max: number): number => Math.floor(Math.random() * (max - min) + min)

/**
 * Chance function
 * @param percentage (0 - 100)
 */
export const chance = (percentage: number) => Math.random() * 100 < percentage

/**
 * Get window width
 */
export const getWindowWidth = () => window.innerWidth

/**
 * Get window height
 */
export const getWindowHeight = () => window.innerHeight
