import React from "react";
import { showDebug } from "./app/utils";
import { Debug} from "./features/debug/Debug";
import { RouterProvider} from "react-router-dom";
import { router } from "./router";
import { Helmet } from "react-helmet";
import { formatDistanceToNow } from "date-fns";

export const App = () => {
  const getYearsOfExperience = () => {
    const years = formatDistanceToNow(new Date(2010, 10, 1))

    return years.charAt(0).toUpperCase() + years.slice(1)
  }

  return (
    <div className="App">
      <Helmet>
        <meta
          name="description"
          content={`Ivan Moskvin - Software Engineer. ${getYearsOfExperience()} experience. I'm in love with web engineering. Developing ecosystem products for social network. Developed CRM, educational platform and dozens of projects in my own studio.`}
        />
      </Helmet>
      { showDebug() && <Debug /> }
      <div className="App__wrapper">
        <RouterProvider router={router} />
      </div>
    </div>
  )
}
