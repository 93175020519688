import { AppThunk } from "../../app/store"
import { decodeChar, selectEncodedCharsIndexes, setName } from "./nameSlice"
import { chance, rand } from "../matrix/utils"
import { NAME } from "./constants"
import { DECODE_NAME_CHAR_CHANCE } from "../../app/constants"
import { startDescription } from "../matrix/matrixSlice"
import { getNextKatakanaGlyph } from "../stream/utils";

/**
 * Name tick
 */
export const nameTick = (): AppThunk => ((dispatch, getState) => {
  const encodedCharsIndexes = selectEncodedCharsIndexes(getState())

  // If some chars still encoded
  if (encodedCharsIndexes.length > 0) {
    dispatch(decodeName())
    dispatch(printName())
    return
  }

  // Name decoded
  dispatch(nameDecoded())
})

/**
 * Decode name
 */
export const decodeName = (): AppThunk => (dispatch, getState) => {
  // Select encoded chars
  const encodedCharsIndexes = selectEncodedCharsIndexes(getState())

  // Try to decode
  if (chance(DECODE_NAME_CHAR_CHANCE)) {
    dispatch(decodeChar(encodedCharsIndexes[rand(0, encodedCharsIndexes.length)]))
  }
}

/**
 * Processing name
 */
export const printName = (): AppThunk => (dispatch, getState) => {
  const encodedCharsIndexes = selectEncodedCharsIndexes(getState())

  const name = new Array(NAME.length).fill(null).map((_, i) => {
    return encodedCharsIndexes.includes(i) ? getNextKatakanaGlyph() : NAME[i]
  }).join("")

  dispatch(setName(name))
}

/**
 * Name decoded
 */
const nameDecoded = (): AppThunk => (dispatch) => {

  dispatch(startDescription())
}
