// TICKS
const MATRIX_TICK_RATE = 15
const NAME_TICK_RATE = 15
export const MATRIX_TICK_INTERVAL = 1000 / MATRIX_TICK_RATE
export const NAME_TICK_INTERVAL = 1000 / NAME_TICK_RATE
export const PERFORMANCE_ADJUSTMENT_TICK_INTERVAL = 800
export const FPS_COUNT_WARMUP_INTERVAL = 3000

// FPS
export const FPS_FLOOR = 50

// STREAMS
export const SHORT_STREAM_SPAWN_CHANCE = 30
export const SHORT_STREAM_MIN_LENGTH = 1
export const SHORT_STREAM_MAX_LENGTH = 5
export const SHORT_STREAM_START_ROW = 0
export const STREAM_MIN_LENGTH = SHORT_STREAM_MAX_LENGTH + 1
export const STREAM_MAX_LENGTH_COEF = 0.7
export const NO_BRIGHT_GLYPHS_STREAM_CHANCE = 33
export const STREAM_REPLACING_START_POSITION = 2
export const SHORT_STREAM_MAX_LENGTH_DIVIDER = 5
export const STREAM_SPAWN_CHUNK_SIZE = 3

// GLYPHS
export const GLYPH_FONT_SIZE = 28
export const GLYPH_WIDTH = Math.floor(GLYPH_FONT_SIZE * 0.53)
export const GLYPH_HEIGHT = Math.floor(GLYPH_FONT_SIZE * 0.87)
export const EMPTY_GLYPH = " "
export const EMPTY_GLYPH_CHANCE = 10

// NAME
export const DECODE_NAME_CHAR_CHANCE = 35

// SHUFFLE
export const STREAM_SHUFFLING_POSITION_DENSITY = 35
export const MIN_SHUFFLING_SPEED = 10
export const MAX_SHUFFLING_SPEED = 30

// KEY CODES
export const SPACE_KEY_CODE = 32

// TIME
export const SECOND = 1000
