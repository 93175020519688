import styles from "./Screen.module.css"
import { useAppSelector } from "../../app/hooks"
import { selectLoading, selectIsMatrixStarted } from "../matrix/matrixSlice"
import classNames from "classnames"
import { ReactNode } from "react";

type ScreenProps = {
  children: ReactNode,
}

export const Screen = ({ children }: ScreenProps) => {
  const loading = useAppSelector(selectLoading)
  const matrixStarted = useAppSelector(selectIsMatrixStarted)

  return (
    <section className={ classNames({
      [styles.Screen]: true,
      [styles["app-stopped"]]: !matrixStarted
    }) } onClick={ (e) => e.stopPropagation() }>
      { loading
        ? <div className={ styles.loading }>Loading...</div>
        : children
      }
    </section>
  )
}
