import { StreamPayloadType, StreamShufflingPositionsSet } from "./type"
import { chance, rand } from "../matrix/utils";
import { GlyphType } from "../glyph/types";
import {
  EMPTY_GLYPH,
  EMPTY_GLYPH_CHANCE,
} from "../../app/constants";

const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '$', '+', '-', '*', '/', '=', '%', "'", '#', '&', '_', '(', ')', ',', '.', ';', ':', '?', '!', '|', '[', ']', '<', '>', '[', ']', '^', '~']
const katakana = ['ﾊ', 'ﾐ', 'ﾋ', 'ｰ', 'ｳ', 'ｼ', 'ﾅ', 'ﾓ', 'ﾆ', 'ｻ', 'ﾜ', 'ﾂ', 'ｵ', 'ﾘ', 'ｱ', 'ﾎ', 'ﾃ', 'ﾏ', 'ｹ', 'ﾒ', 'ｴ', 'ｶ', 'ｷ', 'ﾑ', 'ﾕ', 'ﾗ', 'ｾ', 'ﾈ', 'ｽ', 'ﾀ', 'ﾇ', 'ﾍ']

/**
 * Gets next random glyph
 */
export const getNextGlyph = (noEmptyGlyphs = false): GlyphType => {
  const glyph = alphabet[rand(0, alphabet.length - 1)]

  if(noEmptyGlyphs) {
    return {
      ...getInitialGlyph(),
      payload: glyph
    }
  }

  return {
    ...getInitialGlyph(),
    payload: chance(EMPTY_GLYPH_CHANCE) ? EMPTY_GLYPH : glyph
  }
}
/**
 * Get next random Katakana glyph
 */
export const getNextKatakanaGlyph = (): string => {
  return katakana[rand(0, katakana.length - 1)]
}

/**
 * Replaces random glyph in string
 * @param streamPayload
 * @param startingFrom
 * @param shufflingSpeed
 * @param shufflingPositions
 */
export const replaceRandomChar = (streamPayload: StreamPayloadType, startingFrom: number, shufflingSpeed: number, shufflingPositions: StreamShufflingPositionsSet): StreamPayloadType => {
  const payload = [...streamPayload]

  for(let i = startingFrom; i < payload.length; i++) {
    if(!(i in shufflingPositions)) continue

    payload[i] =
      chance(shufflingSpeed)
        ? getNextGlyph(true)
        : payload[i]
  }

  return payload
}

/**
 * Get initialGlyph
 */
export const getInitialGlyph = (): GlyphType => ({})

/**
 * Get empty char
 */
export const getEmptyGlyph = (): GlyphType => ({
  payload: EMPTY_GLYPH
})
